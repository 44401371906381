/*!
 * froala_editor v1.1.4 (http://editor.froala.com)
 * Copyright 2014-2014 Froala
 */
[contenteditable],
[contenteditable]:focus {
  outline: 0px solid transparent;
}
button {
  outline: none;
}
.f-non-selectable {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.f-non-selectable * {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.froala-editor {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.froala-editor *:not(input):not(textarea) {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.froala-element {
  min-height: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  word-wrap: break-word;
  line-height: 120%;
}
.froala-element hr {
  height: 1px;
  background: #CCC;
  border: 0;
  clear: both;
}
.froala-element * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.froala-element.f-tni span.f-img-wrap {
  display: block;
  text-align: center;
}
.froala-element.f-tni span.f-img-wrap:after {
  clear: both;
  display: block;
  content: "";
}
.froala-element.f-tni span.f-img-editor {
  display: inline-block;
}
.froala-element.f-tni img {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}
.froala-element.f-tni img:after {
  clear: both;
  display: block;
  content: "";
}
.froala-element.f-rtl {
  direction: rtl;
  text-align: right;
}
.froala-element.f-rtl.f-placeholder:before {
  left: auto;
  right: 10px;
}
.froala-element span {
  line-height: 120%;
}
.froala-element img::selection {
  color: #FFF;
  background-color: transparent !important;
}
.froala-element img::-moz-selection {
  color: #FFF;
  background-color: transparent !important;
}
.froala-element.f-placeholder:before {
  color: #cccccc;
  font-style: italic;
  content: attr(data-placeholder);
  position: absolute;
}
.froala-element.f-placeholder:before.f-basic {
  top: 10px;
  left: 10px;
}
.froala-element img {
  max-width: 100% !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border: solid 3px transparent;
  cursor: pointer;
  z-index: 1;
  position: relative;
}
.froala-element p {
  margin-top: 0;
  margin-bottom: 10px;
}
.froala-element h1 {
  font-size: 2em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-element h2 {
  font-size: 1.5em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-element h3 {
  font-size: 1.15em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-element h4 {
  line-height: 120%;
  font-weight: bolder;
}
.froala-element h5 {
  font-size: .83em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-element h6 {
  font-size: .75em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-element pre {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, post millennium */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #cccccc;
  background: #FCFCFC;
  padding: 10px;
  overflow: auto;
}
.froala-element blockquote {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-left: 40px;
  position: relative;
  font-style: italic;
  border: 0;
}
.froala-element blockquote:before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  line-height: 75px;
  color: #353535;
  content: '\201C';
  font-style: normal;
}
.froala-element span.f-img-wrap {
  line-height: 0;
  display: block;
  text-align: center;
}
.froala-element span.f-img-editor {
  display: inline-block;
  position: relative;
  max-width: 100% !important;
  text-align: center;
  border: solid 1px #1A2D35;
  padding: 2px;
  line-height: 0;
}
.froala-element span.f-img-editor img {
  border: 0;
}
.froala-element span.f-img-handle {
  position: absolute;
  height: 15px;
  width: 15px;
  border: solid 1px #353535;
  background: #FFF;
  z-index: 2;
  margin: auto;
}
.froala-element span.f-img-handle.f-h-ne {
  top: -1px;
  left: auto;
  right: -1px;
  bottom: auto;
  cursor: ne-resize;
}
.froala-element span.f-img-handle.f-h-se {
  top: auto;
  left: auto;
  right: -1px;
  bottom: -1px;
  cursor: se-resize;
}
.froala-element span.f-img-handle.f-h-sw {
  top: auto;
  left: -1px;
  right: auto;
  bottom: -1px;
  cursor: sw-resize;
}
.froala-element span.f-img-handle.f-h-nw {
  top: -1px;
  left: -1px;
  right: auto;
  bottom: auto;
  cursor: nw-resize;
}
.froala-element.f-basic {
  border: solid 1px #353535;
  padding: 10px;
}
.froala-element a {
  cursor: pointer;
}
.f-html .froala-element {
  background: #353535;
  color: #FFF;
  font-family: "Courier New", Courier, monospace;
  font-size: 13px;
  overflow-y: auto;
  padding: 0;
}
.f-html .froala-element:after {
  clear: both;
  display: block;
  content: "";
}
.f-html .froala-element textarea {
  direction: ltr !important;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
  padding: 0 0 0 10px;
  background: transparent;
  border: 0 transparent;
  color: #FFF;
  resize: none !important;
  line-height: 140%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  min-height: 100px;
}
.froala-box {
  position: relative;
}
.froala-box .html-switch {
  position: absolute;
  left: 0;
  top: -30px;
  z-index: 1000;
  display: none;
  font-size: 14px;
  line-height: 160%;
  height: 30px;
  border: solid 1px #aaaaaa;
}
.froala-box.f-html .html-switch {
  display: inline-block;
}
.froala-modal {
  position: fixed;
  z-index: 1001;
  overflow-x: auto;
  overflow-y: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}
.froala-modal .f-modal-wrapper {
  margin: 50px auto;
  display: inline-block;
  background: #FFF;
  min-width: 300px;
  text-align: center;
  border: solid 1px #000;
  border-top: solid 5px #000;
}
.froala-modal .f-modal-wrapper .f-preloader {
  margin: 50px 0;
}
.froala-modal .f-modal-wrapper h4 {
  margin: 0;
  font-size: 18px;
  color: #444444;
  margin: 10px 10px;
  text-align: left;
}
.froala-modal .f-modal-wrapper h4:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.froala-modal .f-modal-wrapper h4 i {
  float: right;
  cursor: pointer;
  color: #cccccc;
}
.froala-modal .f-modal-wrapper h4 i:hover {
  color: #777;
}
.froala-modal .f-modal-wrapper div.f-image-list {
  list-style-type: none;
  margin: 10px;
  padding: 0;
  -moz-column-count: 3;
  -moz-column-gap: 10px;
  -webkit-column-count: 3;
  -webkit-column-gap: 10px;
  column-count: 3;
  column-gap: 10px;
  width: 600px;
}
.froala-modal .f-modal-wrapper div.f-image-list div {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}
.froala-modal .f-modal-wrapper div.f-image-list div.f-empty {
  width: 100%;
  height: 200px;
  background: #cccccc;
  position: relative;
  margin-bottom: 10px;
}
.froala-modal .f-modal-wrapper div.f-image-list div.f-empty:after {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "Loading..";
  display: inline-block;
  height: 20px;
}
.froala-modal .f-modal-wrapper div.f-image-list div img {
  width: 100%;
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  vertical-align: middle;
}
.froala-modal .f-modal-wrapper div.f-image-list div .f-delete-img {
  display: none;
  bottom: 0px;
  left: 0;
  background: #353535;
  color: #FFF;
  font-family: Arial, Helvetica;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -ms-transition: background 0.2s;
  -o-transition: background 0.2s;
  cursor: pointer;
}
.froala-modal .f-modal-wrapper div.f-image-list div .f-delete-img {
  display: inline-block;
}
.froala-modal .f-modal-wrapper div.f-image-list:not(.f-touch) .f-delete-img {
  display: none;
}
.froala-modal .f-modal-wrapper div.f-image-list:not(.f-touch) div:hover .f-delete-img {
  display: inline-block;
}
.froala-modal .f-modal-wrapper div.f-image-list:not(.f-touch) div:hover .f-delete-img:hover {
  background: #B8312F;
}
.froala-modal .f-modal-wrapper div.f-image-list:not(.f-touch) div:hover img {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.froala-editor {
  text-align: left;
  background: #FFF;
  border: solid 1px #252528;
  border-top: solid 5px #252528;
  padding: 0;
  position: absolute;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 15px;
  z-index: 1000;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 140%;
}
.froala-editor * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.froala-editor input[type="text"],
.froala-editor textarea {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.froala-editor.f-rtl {
  direction: rtl;
  text-align: right;
}
.froala-editor.f-rtl.f-basic .fr-bttn {
  float: right;
}
.froala-editor.f-rtl.f-basic .f-sep {
  float: right;
}
.froala-editor.f-rtl .fr-dropdown-menu {
  text-align: right !important;
  direction: rtl !important;
}
.froala-editor.f-rtl .fr-dropdown.fr-color-picker .fr-dropdown-menu p {
  text-align: right !important;
  direction: rtl !important;
}
.froala-editor.f-rtl .link-wrapper p label,
.froala-editor.f-rtl .image-wrapper p label,
.froala-editor.f-rtl .video-wrapper p label,
.froala-editor.f-rtl .video-wraper p label,
.froala-editor.f-rtl .link-wrapper p input,
.froala-editor.f-rtl .image-wrapper p input,
.froala-editor.f-rtl .video-wrapper p input,
.froala-editor.f-rtl .video-wraper p input {
  float: right !important;
}
.froala-editor.f-rtl .link-wrapper p button,
.froala-editor.f-rtl .image-wrapper p button,
.froala-editor.f-rtl .video-wrapper p button,
.froala-editor.f-rtl .video-wraper p button {
  float: left !important;
}
.froala-editor.f-rtl .link-wrapper h4 i,
.froala-editor.f-rtl .image-wrapper h4 i,
.froala-editor.f-rtl .video-wrapper h4 i,
.froala-editor.f-rtl .video-wraper h4 i {
  float: left !important;
}
.froala-editor.f-rtl .image-wrapper ul li.url-upload * {
  float: right;
}
.froala-editor:not(.froala-modal):before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom-color: inherit;
  border-bottom-style: solid;
  border-bottom-width: 6px;
  content: '';
  position: absolute;
  top: -10px;
  left: 15px;
}
.froala-editor.f-basic {
  padding: 0px;
  z-index: 999;
  position: relative;
  border-bottom: 0;
}
.froala-editor.f-basic:before {
  display: none;
}
.froala-editor.f-basic:after {
  content: "";
  display: block;
  clear: both;
}
.froala-editor.f-basic .fr-bttn {
  margin: 0;
  float: left;
}
.froala-editor.f-basic.f-scroll {
  position: absolute;
  left: 0;
  right: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
}
.froala-editor.f-basic hr {
  display: none;
}
.froala-editor.right-side:before {
  right: 15px;
  left: auto;
}
.froala-editor .bttn-wrapper {
  white-space: nowrap;
}
.froala-editor hr {
  border: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #cccccc;
  margin: 0 !important;
  height: 0;
}
.froala-editor div.f-clear {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.froala-editor .fr-bttn {
  background: transparent;
  display: inline-block;
  cursor: default;
  width: 40px;
  text-align: center;
  margin: 0px;
}
.froala-editor .fr-bttn img {
  max-width: 40px;
  max-height: 35px;
  display: block;
}
.froala-editor .fr-bttn:disabled {
  color: #aaaaaa !important;
}
.froala-editor .fr-bttn[data-disabled="true"] button,
.froala-editor .fr-bttn[data-disabled="true"] i.for-text {
  color: #aaaaaa !important;
}
.froala-editor .fr-bttn[data-disabled="true"]:after {
  border-top-color: #aaaaaa !important;
}
.froala-editor .fr-bttn i {
  line-height: 170%;
  -moz-outline: 0;
  outline: 0;
}
.froala-editor span.f-sep {
  display: inline-block;
  width: 0px;
  background-color: #f5f5f5;
  height: 35px;
  margin: 0;
  border-right: solid 1px #aaaaaa;
  float: left;
}
.froala-editor button::-moz-focus-inner {
  border: 0;
}
.froala-editor.f-inverse {
  background: #353535;
}
.froala-editor.f-inverse button:not(.fr-color-bttn).fr-trigger,
.froala-editor.f-inverse button:not(.fr-color-bttn).fr-bttn {
  color: #FFF;
}
.froala-editor.f-inverse .fr-dropdown:after {
  border-top-color: #FFF;
}
.froala-editor.f-inverse .fr-dropdown .fr-dropdown-menu {
  background: #353535;
  border-color: #000;
  color: #FFF;
}
.froala-editor.f-inverse .fr-dropdown .fr-dropdown-menu a {
  color: #FFF !important;
}
.froala-editor.f-inverse .fr-dropdown .fr-dropdown-menu a:hover {
  color: #353535 !important;
  background: #FFF !important;
}
.froala-editor.f-inverse .f-image-editor > div label,
.froala-editor.f-inverse .image-wrapper ul li.url-upload label,
.froala-editor.f-inverse .link-wrapper h4,
.froala-editor.f-inverse .image-wrapper h4,
.froala-editor.f-inverse .video-wrapper h4,
.froala-editor.f-inverse .link-wrapper p label {
  color: #FFF;
}
.froala-editor button:not(.fr-color-bttn).fr-trigger,
.froala-editor button:not(.fr-color-bttn).fr-bttn {
  padding: 0;
  background: transparent;
  color: #353535;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  font-size: 16px;
  line-height: 35px;
}
.froala-editor button:not(.fr-color-bttn).fr-trigger.active,
.froala-editor button:not(.fr-color-bttn).fr-bttn.active {
  color: #2c82c9;
}
.froala-editor button:not(.fr-color-bttn).fr-trigger:hover:not(:disabled),
.froala-editor button:not(.fr-color-bttn).fr-bttn:hover:not(:disabled),
.froala-editor .fr-dropdown:hover button.fr-trigger:not(:disabled) {
  color: #FFF !important;
  background: #2c82c9 !important;
  cursor: pointer;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -ms-transition: background 0.2s;
  -o-transition: background 0.2s;
}
.froala-editor .fr-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.froala-editor .fr-dropdown:after {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #353535;
  content: '';
  position: absolute;
  top: 15px;
  right: 8px;
  color: inherit;
  cursor: pointer;
}
.froala-editor .fr-dropdown:hover:after,
.froala-editor .fr-dropdown.active:after {
  border-top-color: #FFF;
}
.froala-editor .fr-dropdown.active button.fr-trigger {
  color: #FFF;
  background: #2c82c9;
}
.froala-editor .fr-dropdown.active .fr-dropdown-menu {
  display: block;
}
.froala-editor .fr-dropdown.fr-family .fr-trigger {
  font-family: "Courier New", Courier, monospace;
  font-size: 22px;
  height: 35px;
  overflow: hidden;
}
.froala-editor .fr-dropdown.fr-family .fr-trigger i {
  margin-left: 3px;
}
.froala-editor .fr-dropdown .fr-trigger {
  border: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  display: inline-block;
  width: 40px;
  padding-left: 2px;
  font-size: 16px;
}
.froala-editor .fr-dropdown .fr-trigger i {
  margin-left: 5px;
}
.froala-editor .fr-dropdown .fr-dropdown-menu {
  display: none;
  position: absolute;
  text-align: left;
  left: 0px;
  top: 35px;
  margin-top: 0;
  padding: 0;
  background: #FFF;
  border: solid 1px #cccccc;
  z-index: 1000;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li {
  list-style-type: none;
  position: relative;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li button.fr-bttn {
  margin: 5px 5px;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li.active:before {
  position: absolute;
  left: 6px;
  top: 5px;
  content: "\f00c";
  font-family: 'FontAwesome';
  font-weight: normal;
  font-size: 12px;
  color: #666;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a {
  padding: 5px 25px;
  display: block;
  text-decoration: none;
  color: #353535;
  outline: 0;
  white-space: nowrap;
  font-size: 14px;
  line-height: 130%;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a:hover {
  background: #353535 !important;
  color: #FFF !important;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_blockquote {
  font-style: italic;
  color: #353535;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_pre {
  font-family: monospace, sans-serif;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_h1 {
  font-size: 2em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_h2 {
  font-size: 1.5em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_h3 {
  font-size: 1.17em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_h4 {
  line-height: 120%;
  font-weight: bolder;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_h5 {
  font-size: .83em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-editor .fr-dropdown .fr-dropdown-menu li a.format_h6 {
  font-size: .75em;
  line-height: 120%;
  font-weight: bolder;
}
.froala-editor .fr-dropdown .fr-dropdown-menu hr {
  clear: both;
  height: 0;
  border: 0;
  display: block;
}
.froala-editor .fr-dropdown .fr-dropdown-menu:after {
  clear: both;
  content: "";
  display: block;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu {
  padding: 10px 10px 10px;
  line-height: 0px;
  white-space: nowrap;
  text-align: left;
  direction: ltr !important;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu > div {
  white-space: nowrap;
  display: inline-block;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu > div + div {
  margin-left: 12px;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu p {
  margin: 6px 0px 12px 0px;
  font-size: 13px;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu .fr-color-bttn {
  display: inline;
  width: 18px;
  height: 18px;
  padding: 0;
  border: solid 1px #FFF;
  position: relative;
  z-index: 100;
  line-height: 16px !important;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu .fr-color-bttn.active {
  border: solid 1px #FFF;
  outline: solid 1px #000;
  z-index: 101;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu .fr-color-bttn.active:after {
  position: absolute;
  left: 2px;
  top: 1px;
  content: "\f00c";
  font-family: 'FontAwesome';
  font-weight: normal;
  font-size: 12px;
  color: #FFF;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu .separator {
  height: 8px;
}
.froala-editor .fr-dropdown.fr-color-picker .fr-dropdown-menu .fr-color-bttn:hover:not(:focus):not(:active) {
  border: solid 1px #000;
}
.froala-editor i.for-text {
  font-style: normal;
  font-weight: bold;
  padding-left: 4px;
}
.froala-editor .link-wrapper,
.froala-editor .image-wrapper,
.froala-editor .video-wrapper {
  font-size: 13px;
}
.froala-editor .link-wrapper *:not(input):not(textarea),
.froala-editor .image-wrapper *:not(input):not(textarea),
.froala-editor .video-wrapper *:not(input):not(textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.froala-editor .link-wrapper h4,
.froala-editor .image-wrapper h4,
.froala-editor .video-wrapper h4 {
  margin: 0;
  font-size: 14px;
  color: #444444;
  margin: 7px 5px;
}
.froala-editor .link-wrapper h4:after,
.froala-editor .image-wrapper h4:after,
.froala-editor .video-wrapper h4:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.froala-editor .link-wrapper h4 i,
.froala-editor .image-wrapper h4 i,
.froala-editor .video-wrapper h4 i {
  float: right;
  cursor: pointer;
  color: #cccccc;
}
.froala-editor .link-wrapper h4 i:hover,
.froala-editor .image-wrapper h4 i:hover,
.froala-editor .video-wrapper h4 i:hover {
  color: #777;
}
.froala-editor .video-wrapper p {
  margin: 5px;
}
.froala-editor .video-wrapper p:after {
  clear: both;
  display: block;
  content: "";
}
.froala-editor .video-wrapper p button {
  float: right;
}
.froala-editor .video-wrapper textarea {
  width: 290px;
  padding: 5px;
  height: 65px;
  overflow-x: auto;
  margin: 0 5px;
  resize: none;
  border: solid 1px #cccccc;
}
.froala-editor .video-wrapper textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(102, 175, 233, 0.6);
}
.froala-editor .link-wrapper p {
  margin: 10px 5px;
}
.froala-editor .link-wrapper p:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.froala-editor .link-wrapper p input[type="checkbox"] {
  float: left;
  margin-top: 5px;
  margin-left: 0;
}
.froala-editor .link-wrapper p label {
  float: left;
  margin: 5px 5px 0;
  line-height: 120%;
}
.froala-editor .link-wrapper input[type="text"] {
  font-size: 13px;
  width: 250px;
  border: solid 1px #cccccc;
  margin: 0 5px;
  height: 21px;
}
.froala-editor .link-wrapper button {
  float: right;
}
.froala-editor .image-wrapper h4 {
  margin-bottom: 15px;
}
.froala-editor .image-wrapper ul {
  list-style-type: none;
  padding: 0 5px;
  margin: 0 0 10px 0;
  min-width: 290px;
}
.froala-editor .image-wrapper ul li {
  padding: 0;
  margin: 0;
  position: relative;
  white-space: nowrap;
}
.froala-editor .image-wrapper ul li + li {
  border-top: solid 1px #EEE;
  margin-top: 8px;
  padding-top: 8px;
}
.froala-editor .image-wrapper ul li.drop-upload div.f-upload {
  border: dashed 2px #cccccc;
  color: #777;
  font-weight: normal;
  text-align: center;
  padding: 10px 0;
}
.froala-editor .image-wrapper ul li.drop-upload div.f-upload:hover {
  border: dashed 2px #555;
}
.froala-editor .image-wrapper ul li.drop-upload div.f-upload.f-hover {
  border: 2px dashed #0c0;
}
.froala-editor .image-wrapper ul li.drop-upload div.f-upload strong {
  font-size: 14px;
}
.froala-editor .image-wrapper ul li.drop-upload form {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}
.froala-editor .image-wrapper ul li.drop-upload form input {
  display: inline-block;
  width: 400%;
  margin-left: -300%;
  height: 100%;
  cursor: pointer;
  font-size: 1000px !important;
}
.froala-editor .image-wrapper ul li.url-upload * {
  float: left;
}
.froala-editor .image-wrapper ul li.url-upload:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.froala-editor .image-wrapper ul li.url-upload label {
  padding: 3px 0;
  line-height: 120%;
}
.froala-editor .image-wrapper ul li.url-upload input {
  font-size: 13px;
  width: 170px;
  border: solid 1px #777;
  margin: 0 0 0 5px;
  height: 19px;
  padding: 1px 5px;
}
.froala-editor .image-wrapper ul li.url-upload input + .f-browse {
  margin: 0 5px 0 0;
  height: 19px;
  padding: 2px 7px;
  font-size: 13px;
  border: none;
  background: #777;
  color: #FFF;
  cursor: pointer;
}
.froala-editor .image-wrapper ul li.url-upload input + .f-browse:hover {
  background: #353535;
}
.froala-editor .image-wrapper ul li.url-upload input + .f-ok {
  margin-left: 5px;
}
.froala-editor .image-wrapper ul li.url-upload input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(102, 175, 233, 0.6);
}
.froala-editor .image-wrapper p.f-progress {
  width: 100%;
  display: none;
  background-color: #f3f3f3;
  border: 0;
  height: 20px;
  margin: 5px 0;
  width: 250px;
}
.froala-editor .image-wrapper p.f-progress span {
  display: inline-block;
  height: 100%;
  background-color: #00C13F;
  text-align: center;
  color: #FFF;
  line-height: 20px;
}
.f-image-editor > div {
  padding: 5px 10px;
}
.f-image-editor > div label {
  color: #353535;
  font-weight: normal;
  display: inline-block;
  font-size: 13px;
}
.f-image-editor > div input[type="text"] {
  font-size: 12px;
  padding: 0 5px;
  width: 150px;
  border: solid 1px #cccccc;
  margin: 0 5px;
  height: 21px;
}
.f-image-editor > div input[type="text"]:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(102, 175, 233, 0.6);
}
button.f-ok {
  background: #1189D9;
  border: 0;
  color: #FFF;
  padding: 2px 5px;
  margin: 0;
  font-size: 12px;
  line-height: 19px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: background linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: background linear 0.2s, box-shadow linear 0.2s;
  -o-transition: background linear 0.2s, box-shadow linear 0.2s;
  transition: background linear 0.2s, box-shadow linear 0.2s;
}
button.f-ok:hover {
  background: #353535 !important;
  color: #FFF;
}
button.f-ok.f-unlink {
  background: #b8312f;
  margin: 0 5px;
}
.f-font-sizes {
  max-height: 200px;
  overflow-y: scroll;
}
.f-font-sizes a {
  padding: 5px 7px !important;
  font-size: 12px;
  line-height: 160%;
}
.f-font-sizes li.active {
  background: #353535;
}
.f-font-sizes li.active:before {
  content: "" !important;
}
.f-font-sizes li.active a {
  color: #FFF !important;
}
.froala-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  z-index: 1000;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
}
