body {
  background: url(../images/bg_fly.jpg) center 0 repeat fixed;
  font-family: "Roboto", "open-sans", sans-serif;
  font-weight: 500;
  position: relative;
}

.white-theme {
  .container {
    &.page {
      background-color: #fff;
      border-radius: 5px 5px 0 0;
    }
  }
}

.table {
  font-style: italic;
  > thead > tr > td {
    background-color: #003459;
    color: #fff;
  }
}

.text-blue {
  color: #003459;
}

.text-pink {
  color: #ee4266;
}

.text-light-gray {
  color: #3cbbb1;
}

.container {
  &.transparent {
    background-color: transparent;
  }
  &.white {
    background-color: #FFFFFF;
  }
  &.blue {
    background-color: #003459;
  }
  &.page {
    padding-top: 25px;
    padding-bottom: 40px;
  }
  &.program-page {
    padding-bottom: 0;
  }
  width: 970px;
  max-width: none !important;
}

.tab-content-background {
  background: url(/media/images/pyramid.png) center bottom no-repeat;
  padding-bottom: 40px;
}

.tab-content-static {
  padding: 20px 10px 40px 10px;
  overflow: hidden;
  min-height: 350px;
}

.promo-wg { padding-left: 0; padding-right: 0; }
.weather-wg { padding-left: 0; padding-right: 0; }

.fc-weather {
  background-color: #f5f8fc;
  padding: 5px 10px;
  img { margin-right: 5px; }
  margin-top: 253px;
  height: 50px;
}

.container-promo { margin-bottom: 15px; }

.text-header {
  text-transform: uppercase;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 25px;
  position: relative;
  .text-left-side {
    position: absolute;
    left: 0;
    &.img-circle {
      width: 35px;
      height: 35px;
      line-height:35px;
      text-align: center;
      top: -8px;
      &.fill-blue {
        background-color: #003459;
        color: #fff;
      }
      &.fill-light-gray {
        background-color: #3cbbb1;
        color: #fff;
      }
      &.fill-pink {
        background-color: #ee4266;
        color: #fff;
      }
    }
  }
}

.text-header-bold {
  &:extend(.text-header all);
  font-weight: 500;
}

.control-label {
  font-size: 15px;
}

.form-control-box {
  margin-bottom: 15px;
  .control-label {
    margin-bottom: 0;
    padding-top: 9px;
    padding-bottom: 8px;
  }
  .control-validation {
    margin-bottom: 0;
    line-height: 38px;
    font-size: 14px;
    color: #939393;
  }
}

.article {
  p {
    font-style: italic;}
}

.double-side-block() {

  perspective: 1000px;
  z-index: 1;

  .side-holder {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.3s;
  }

  &:hover .side-holder {
    transform: rotateY(180deg);
  }

  cursor: default;

  .face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

    &.back {
      transform: rotateY(180deg);
      backface-visibility: hidden;
    }
  }
}

.double-side-block-card {
  .double-side-block();

  margin: 10px 15px;
  color: #fff;

  &:hover .side-holder {
    box-shadow: -5px 5px 5px #aaa;
  }

  .face {
    background-color: #236794;
    padding: 10px 5px;
    width: 180px;
    height: 245px;

    &:not(.back) {
      .name {
        font-size: 20px;
        text-align: center;
      }
      .price {
        font-size: 28px;
        text-align: center;
        margin-top: 40px;
      }
      .text {
        font-size: 16px;
        padding: 0 30px;
        margin-top: 40px;
        font-style: italic;
      }
    }

    &.back {
      background: #ee4266;
      text-align: center;
      line-height: 24px;
      font-size: 16px;
      position: relative;

      .text {
        font-style: italic;
      }

      .toolbox {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: center;
      }

      .btn { padding-left: 20px; padding-right: 20px; }
    }
  }
}

.double-side-block-rounded {

  .double-side-block();

  width: 164px;
  height: 164px;

  margin-bottom: 32px;
  margin-left: 22px;

  .face {
    background-color: #236794;
    width: 164px;
    height: 164px;
    border-radius: 50%;
    overflow: hidden;

    &.back {
      text-align: center;
      color: #FFF;
      padding: 10px 0;
      font-size: 15px;
      background: #ee4266;
      .text {
        height: 100%;
        border: medium none;
        background-color: transparent;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        &:hover {
          background-color: transparent;
          padding: 0;
        }
      }
    }
  }
}

.list-marker {
  background: url(../images/list_marker.png) left 5px no-repeat;
  padding-left: 20px;
}

.tour-plate {
  position: relative;
  margin-bottom: 20px;
  .bottom-checker { bottom: 0; position: absolute; }
  .double-side-block-card { margin-top: 0; margin-bottom: 0; }
  .tour-text { position: static; }
}

.bread-crumbs {
  margin-bottom: 20px;
}

.tour-collection {
  margin-bottom: 20px;
}

.service-collection {
  margin-bottom: 20px;
}

.news-collection {
  margin-bottom: 20px;
}

.news-item {
  margin-bottom: 10px;
  h4 {
    font-size: 16px;
    font-weight: 500;
    > a {
      color: #000;
    }
  }
  .date {
    font-size: 14px;
    color: #747373;
  }
}

.nivo-controlNav {
  display: none;
}

.btn-logout-fly {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 200px;
  left: -40px;
}

.form-login-fly {

  padding-right: 31px;
  width: 480px;
  position: fixed;
  left: -450px;
  top: 150px;
  transition: all 0.5s;
  z-index: 100000;

  &:hover {
    left: 0;
  }

  form {
    background-color: #FFF;
    border: 1px solid #ec2b53;
    border-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 20px;
  }

  .btn-login-fly {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    position: absolute;
    right: -32px;
    top: 90px;
    padding-left: 30px;
    padding-right: 30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  h4 {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.theme-default .nivoSlider {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.list-buttons {
  > li {
    margin-bottom: 5px;
  }
}

footer {
  color: #ebebeb;
  .container {
    padding: 22px 0 35px;
    ul {
      > li a {
        color: #ebebeb;
      }
    }

    .logo-block {
      padding-left: 55px;
      .copyrights {
        margin-top: 60px;
      }
    }

    .menu-block {
      padding-top: 15px;
      padding-left: 65px;

      > li {
        margin-bottom: 10px;
      }
    }

    .contact-block {
      padding-top: 15px;
      padding-left: 125px;

      > li {
        margin-bottom: 10px;
        > a {
          > img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.admin-photos a{text-decoration:none;}
.file-upload-box .list, .admin-photos{overflow:hidden;padding-top:10px;}
.file-upload-box .list p, .admin-photos a{float:left; height:180px; width: 180px; overflow:hidden;margin:5px;background-color:#f1f1f1;border:1px solid #efefef;cursor:pointer;}
.file-upload-box .list p:hover, .admin-photos a:hover{border-color:darkred;}