body {
  background: url(../images/bg_fly.jpg) center 0 repeat fixed;
  font-family: "Roboto", "open-sans", sans-serif;
  font-weight: 500;
  position: relative;
}
.white-theme .container.page {
  background-color: #fff;
  border-radius: 5px 5px 0 0;
}
.table {
  font-style: italic;
}
.table > thead > tr > td {
  background-color: #003459;
  color: #fff;
}
.text-blue {
  color: #003459;
}
.text-pink {
  color: #ee4266;
}
.text-light-gray {
  color: #3cbbb1;
}
.container {
  width: 970px;
  max-width: none !important;
}
.container.transparent {
  background-color: transparent;
}
.container.white {
  background-color: #FFFFFF;
}
.container.blue {
  background-color: #003459;
}
.container.page {
  padding-top: 25px;
  padding-bottom: 40px;
}
.container.program-page {
  padding-bottom: 0;
}
.tab-content-background {
  background: url(/media/images/pyramid.png) center bottom no-repeat;
  padding-bottom: 40px;
}
.tab-content-static {
  padding: 20px 10px 40px 10px;
  overflow: hidden;
  min-height: 350px;
}
.promo-wg {
  padding-left: 0;
  padding-right: 0;
}
.weather-wg {
  padding-left: 0;
  padding-right: 0;
}
.fc-weather {
  background-color: #f5f8fc;
  padding: 5px 10px;
  margin-top: 253px;
  height: 50px;
}
.fc-weather img {
  margin-right: 5px;
}
.container-promo {
  margin-bottom: 15px;
}
.text-header,
.text-header-bold {
  text-transform: uppercase;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 25px;
  position: relative;
}
.text-header .text-left-side,
.text-header-bold .text-left-side {
  position: absolute;
  left: 0;
}
.text-header .text-left-side.img-circle,
.text-header-bold .text-left-side.img-circle {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  top: -8px;
}
.text-header .text-left-side.img-circle.fill-blue,
.text-header-bold .text-left-side.img-circle.fill-blue {
  background-color: #003459;
  color: #fff;
}
.text-header .text-left-side.img-circle.fill-light-gray,
.text-header-bold .text-left-side.img-circle.fill-light-gray {
  background-color: #3cbbb1;
  color: #fff;
}
.text-header .text-left-side.img-circle.fill-pink,
.text-header-bold .text-left-side.img-circle.fill-pink {
  background-color: #ee4266;
  color: #fff;
}
.text-header-bold {
  font-weight: 500;
}
.control-label {
  font-size: 15px;
}
.form-control-box {
  margin-bottom: 15px;
}
.form-control-box .control-label {
  margin-bottom: 0;
  padding-top: 9px;
  padding-bottom: 8px;
}
.form-control-box .control-validation {
  margin-bottom: 0;
  line-height: 38px;
  font-size: 14px;
  color: #939393;
}
.article p {
  font-style: italic;
}
.double-side-block-card {
  perspective: 1000px;
  z-index: 1;
  cursor: default;
  margin: 10px 15px;
  color: #fff;
}
.double-side-block-card .side-holder {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.3s;
}
.double-side-block-card:hover .side-holder {
  transform: rotateY(180deg);
}
.double-side-block-card .face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.double-side-block-card .face.back {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.double-side-block-card:hover .side-holder {
  box-shadow: -5px 5px 5px #aaa;
}
.double-side-block-card .face {
  background-color: #236794;
  padding: 10px 5px;
  width: 180px;
  height: 245px;
}
.double-side-block-card .face:not(.back) .name {
  font-size: 20px;
  text-align: center;
}
.double-side-block-card .face:not(.back) .price {
  font-size: 28px;
  text-align: center;
  margin-top: 40px;
}
.double-side-block-card .face:not(.back) .text {
  font-size: 16px;
  padding: 0 30px;
  margin-top: 40px;
  font-style: italic;
}
.double-side-block-card .face.back {
  background: #ee4266;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  position: relative;
}
.double-side-block-card .face.back .text {
  font-style: italic;
}
.double-side-block-card .face.back .toolbox {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}
.double-side-block-card .face.back .btn {
  padding-left: 20px;
  padding-right: 20px;
}
.double-side-block-rounded {
  perspective: 1000px;
  z-index: 1;
  cursor: default;
  width: 164px;
  height: 164px;
  margin-bottom: 32px;
  margin-left: 22px;
}
.double-side-block-rounded .side-holder {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.3s;
}
.double-side-block-rounded:hover .side-holder {
  transform: rotateY(180deg);
}
.double-side-block-rounded .face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.double-side-block-rounded .face.back {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.double-side-block-rounded .face {
  background-color: #236794;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  overflow: hidden;
}
.double-side-block-rounded .face.back {
  text-align: center;
  color: #FFF;
  padding: 10px 0;
  font-size: 15px;
  background: #ee4266;
}
.double-side-block-rounded .face.back .text {
  height: 100%;
  border: medium none;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.double-side-block-rounded .face.back .text:hover {
  background-color: transparent;
  padding: 0;
}
.list-marker {
  background: url(../images/list_marker.png) left 5px no-repeat;
  padding-left: 20px;
}
.tour-plate {
  position: relative;
  margin-bottom: 20px;
}
.tour-plate .bottom-checker {
  bottom: 0;
  position: absolute;
}
.tour-plate .double-side-block-card {
  margin-top: 0;
  margin-bottom: 0;
}
.tour-plate .tour-text {
  position: static;
}
.bread-crumbs {
  margin-bottom: 20px;
}
.tour-collection {
  margin-bottom: 20px;
}
.service-collection {
  margin-bottom: 20px;
}
.news-collection {
  margin-bottom: 20px;
}
.news-item {
  margin-bottom: 10px;
}
.news-item h4 {
  font-size: 16px;
  font-weight: 500;
}
.news-item h4 > a {
  color: #000;
}
.news-item .date {
  font-size: 14px;
  color: #747373;
}
.nivo-controlNav {
  display: none;
}
.btn-logout-fly {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 200px;
  left: -40px;
}
.form-login-fly {
  padding-right: 31px;
  width: 480px;
  position: fixed;
  left: -450px;
  top: 150px;
  transition: all 0.5s;
  z-index: 100000;
}
.form-login-fly:hover {
  left: 0;
}
.form-login-fly form {
  background-color: #FFF;
  border: 1px solid #ec2b53;
  border-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 20px;
}
.form-login-fly .btn-login-fly {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  position: absolute;
  right: -32px;
  top: 90px;
  padding-left: 30px;
  padding-right: 30px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-login-fly h4 {
  margin-top: 5px;
  margin-bottom: 15px;
}
.theme-default .nivoSlider {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.list-buttons > li {
  margin-bottom: 5px;
}
footer {
  color: #ebebeb;
}
footer .container {
  padding: 22px 0 35px;
}
footer .container ul > li a {
  color: #ebebeb;
}
footer .container .logo-block {
  padding-left: 55px;
}
footer .container .logo-block .copyrights {
  margin-top: 60px;
}
footer .container .menu-block {
  padding-top: 15px;
  padding-left: 65px;
}
footer .container .menu-block > li {
  margin-bottom: 10px;
}
footer .container .contact-block {
  padding-top: 15px;
  padding-left: 125px;
}
footer .container .contact-block > li {
  margin-bottom: 10px;
}
footer .container .contact-block > li > a > img {
  margin-right: 5px;
}
.admin-photos a {
  text-decoration: none;
}
.file-upload-box .list,
.admin-photos {
  overflow: hidden;
  padding-top: 10px;
}
.file-upload-box .list p,
.admin-photos a {
  float: left;
  height: 180px;
  width: 180px;
  overflow: hidden;
  margin: 5px;
  background-color: #f1f1f1;
  border: 1px solid #efefef;
  cursor: pointer;
}
.file-upload-box .list p:hover,
.admin-photos a:hover {
  border-color: darkred;
}
