/*Now the CSS*/
.tree{overflow:hidden;width:50000px;}
.tree ul {
    padding-top: 20px;
    padding-left: 0px;
    position: relative;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.tree li {
    float: left; text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 2px 0 2px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before, .tree li::after{
    content: '';
    position: absolute; top: 0; right: 50%;
    border-top: 3px double orange;
    width: 50%; height: 20px;
}
.tree li::after{
    right: auto; left: 50%;
    border-left: 3px double orange;
}

/*We need to remove left-right connectors from elements without
any siblings*/
.tree li:only-child::after, .tree li:only-child::before {
    display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child{ padding-top: 0;}

/*Remove left connector from first child and
right connector from last child*/
.tree li:first-child::before, .tree li:last-child::after{
    border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before{
    border-right: 3px double orange;
    /*
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
    */
}
.tree li:first-child::after{
    /*
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
    */
}

/*Time to add downward connectors from parents*/
.tree ul ul::before{
    content: '';
    position: absolute; top: 0; left: 50%;
    border-left: 3px double orange;
    width: 0; height: 20px;
}

.tree li a {
    > img {
        width: 80px;
        height: 80px;
    }
    > span {
        background-color: rgba(50, 50, 50, 0.3);
        padding: 0 5px;
        border-radius: 3px;
        color: #fff;
    }
    text-decoration: none;
    color: #555555;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    /*
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    */
    text-align:center;
    width: 95px;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover, .tree li a:hover+ul li a {

}
/*Connector styles on hover*/
.tree li a:hover+ul li::after,
.tree li a:hover+ul li::before,
.tree li a:hover+ul::before,
.tree li a:hover+ul ul::before{
    border-color:  #94a0b4;
}

.user-tree-holder h3:first-child {margin-top: 0;}
.user-tree-holder .tree{
    width:auto;
    margin:0px auto;
}

.user-tree-holder .tree .free-place a{

}

.user-tree-holder .tree a.my-place{

}

.user-tree-holder .tree a:hover, .user-tree-holder .tree a:hover+ul li a,
.user-tree-holder .tree .free-place a:hover, .user-tree-holder .tree .free-place a:hover+ul li a,
.user-tree-holder .tree a.my-place:hover, .user-tree-holder .tree a.my-place:hover+ul li a {
}